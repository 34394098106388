
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import { BrowserRouter as Router, Switch, Route}
from 'react-router-dom';
import Footer from './components/Footer';
import Service from './components/pages/Service';
import About from './components/pages/About';
import Contact from './components/pages/Contact';
import Casualty from './components/pages/services/Casualty';
import Engineering from './components/pages/services/Engineering';
import GroupLife from './components/pages/services/GroupLife';
import GroupMedical from './components/pages/services/GroupMedical';
import HeavyEquipment from './components/pages/services/HeavyEquipment';
import Liability from './components/pages/services/Liability';
import Marine from './components/pages/services/Marine';
import MotorVehicle from './components/pages/services/MotorVehicle';
import PersonalAccident from './components/pages/services/PersonalAccident';
import Property from './components/pages/services/Property';
import Surety from './components/pages/services/Surety';
import Tpa from './components/pages/services/Tpa';
import Travel from './components/pages/services/Travel';
import GroupHealth from './components/pages/services/GroupHealth';
import Invidual from './components/pages/services/Invidual';
import Misc from './components/pages/services/Misc';


function App() {
  return (
  <>
    <Router>
        <Navbar />
        <Switch>
          <Route path='/' exact>
            <Home />
          </Route>
          <Route path='/service'>
           <Service />
          </Route>
          <Route path='/about'>
          <About />
          </Route>
          <Route path='/contact'>
          <Contact />
          </Route>

          <Route path='/casualty'>
           <Casualty />
          </Route>

          <Route path='/engineering'>
           <Engineering />
          </Route>

          <Route path='/group-life'>
           <GroupLife />
          </Route>

          <Route path='/group-health'>
           <GroupHealth />
          </Route>

          <Route path='/group-medical'>
           <GroupMedical />
           </Route>

           <Route path='/heavy-equipment'>
           <HeavyEquipment />
           </Route>

           <Route path='/liability'>
           <Liability />
           </Route>

           <Route path='/misc'>
           <Misc />
           </Route>

           <Route path='/invidual'>
           <Invidual />
           </Route>

           <Route path='/marine'>
           <Marine />
          </Route>

          <Route path='/motor-vehicle'>
           <MotorVehicle />
          </Route>

          <Route path='/personal-accident'>
           <PersonalAccident />
           </Route>

           <Route path='/property'>
           <Property />
          </Route>

          <Route path='/surety'>
           <Surety />
          </Route>
          <Route path='/tpa'>
           <Tpa />
          </Route>

          <Route path='/travel'>
           <Travel />
          </Route>



          {/* <Route path='/casualty' component={Casualty} /> */}
          {/* <Route path='/engineering' component={engineering} />
          <Route path='/group-life' component={groupLife} />
          <Route path='/group-medical' component={groupMedical} />
          <Route path='/heavy-equipment' component={heavyEquipment} />
          <Route path='/liability' component={liability} />
          <Route path='/marine' component={marine} />
          <Route path='/motor-vehicle' component={motorVehicle} />
          <Route path='/personal-accident' component={personalAccident} />
          <Route path='/property' component={property} />
          <Route path='/surety' component={surety} />
          <Route path='/tpa' component={tpa} />
          <Route path='/travel' component={travel} /> */}
        </Switch>
        <Footer/>
      </Router>


  </>

  );
}

export default App;
