/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../../App.css";
// import {Link} from 'react-router-dom';

function Liability() {
    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Liability Insurance</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Service</li>

    </ol> */}

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row gutter-40 col-mb-80">
						{/* <!-- Post Content
						============================================= --> */}
						<div className="postcontent col-lg-12">

							<div className="single-post mb-0">

								{/* <!-- Single Post
								============================================= --> */}
								<div className="entry clearfix">

									{/* <!-- Entry Title
									============================================= --> */}
									{/* <div className="entry-title">
										<h2>This is a Standard post with a Preview Image</h2>
									</div> */}
                                    {/* <!-- .entry-title end --> */}

									{/* <!-- Entry Meta
									============================================= --> */}
									{/* <div className="entry-meta">
										<ul>
											<li><i className="icon-calendar3"></i> 10th July 2021</li>
											<li><a href="#"><i className="icon-user"></i> admin</a></li>
											<li><i className="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="#"><i className="icon-comments"></i> 43 Comments</a></li>
											<li><a href="#"><i className="icon-camera-retro"></i></a></li>
										</ul>
									</div> */}
                                    {/* <!-- .entry-meta end --> */}

									{/* <!-- Entry Image
									============================================= --> */}
									<div className="entry-image">
										<a href="#"><img src="/assets/images/custom/pages/banner/11_LIABILITY_INSURANCE.jpg" alt="Blog Single"/></a>
									</div>
                                    {/* <!-- .entry-image end --> */}

									{/* <!-- Entry Content
									============================================= --> */}
									<div className="entry-content mt-0">

										<p>Guarantee legal liability to third parties in the form of bodily injury and/or property damage in connection with work or business activities carried out by the insured. This insurance consists of:</p>

										<p>Liability Insurance Guarantee, include :</p>
										<h4>PUBLIC LIABILITY INSURANCE (PLI)</h4>
										<p>Guarantee legal liability to third parties in the form of bodily injury and/or property damage in connection with work or business activities carried out by the insured</p>

										{/* <blockquote><p>Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.</p></blockquote> */}
										<h4>COMPREHENSIVE GENERAL LIABILITY (CGL)</h4>
										<p>Insurance that guarantees legal liability to third parties in the event of Personal Injury and/or Property Damage that occurs, which is caused by an event related to the business or activities of the Insured including legal fees</p>

										<h4>PROFESSIONAL INDEMNITY INSURANCE (PI)</h4>
										<p>Protect professionals against various potential actions arising from a violation of their professional obligations in conducting professional business practices.
										Scope of Guarantee:
											<ul style={{marginLeft:"2rem"}}>
											<li>Out-of-court settlement</li>
											<li>Compensation decided by the court to professionals</li>
											<li>Costs and expenses for the defense</li>
											</ul>

										</p>


										<h4>DIRECTORS &amp; OFFICERS LIABILITY INSURANCE (D&amp;O)</h4>
										<p>Insurance that is intended to provide protection for executives and officers in the company to be free from the risk of taking business that they do where third parties can sue.</p>

										<h4>TERMINAL OPERATOR LIABILITY INSURANCE (TOL)</h4>
										<p>Insurance that provides protection against third party lawsuits as a result of the work carried out by the port operator or by sub-contractors who work on the basis of a contract or agreement</p>

										<h4>FREIGHT FORWARDER LIABILITY INURANCE (FFL)</h4>
										<p>Provide complete guarantees to freight forwarders for all goods transportation service activities, in connection with liability received because they are considered or declared legally responsible</p>

										<h4>AUTOMOBILE LIABILITY (AL)</h4>
										<p>Covering legal claims from the employee while carrying out business activities from his employer (employee owner), where the employee suffers bodily injury including death</p>

										<h4>WORKMENS COMPENSATION ACT (WCA)</h4>
										<p>Provide compensation/compensation to workers employed by the insured in order to carry out the work. Compensation to workers is also provided for illnesses that may arise as a result of work. This WCI also aims to get additional protection from the government's compulsory insurance (Jamsostek) so that workers get greater benefits. The benefits received are based on the employee's salary. WCI is said to be a unique insurance product, has a rather high frequency of claims, moderate severity and its application varies from country to country.</p>


										{/* <!-- Post Single - Content End --> */}

										{/* <!-- Tag Cloud
										============================================= --> */}
										<div className="tagcloud clearfix bottommargin">
											<a href="#">insurance</a>
											<a href="#">liability</a>
											<a href="#">general</a>
											<a href="#">comprehensive</a>
											<a href="#">professional</a>
											<a href="#">compensation</a>
										</div>
                                        {/* <!-- .tagcloud end --> */}

										<div className="clear"></div>

										{/* <!-- Post Single - Share
										============================================= --> */}
										{/* <div className="si-share border-0 d-flex justify-content-between align-items-center">
											<span>Share this Post:</span>
											<div>
												<a href="#" className="social-icon si-borderless si-facebook">
													<i className="icon-facebook"></i>
													<i className="icon-facebook"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-twitter">
													<i className="icon-twitter"></i>
													<i className="icon-twitter"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-pinterest">
													<i className="icon-pinterest"></i>
													<i className="icon-pinterest"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-gplus">
													<i className="icon-gplus"></i>
													<i className="icon-gplus"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-rss">
													<i className="icon-rss"></i>
													<i className="icon-rss"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-email3">
													<i className="icon-email3"></i>
													<i className="icon-email3"></i>
												</a>
											</div>
										</div> */}
                                        {/* <!-- Post Single - Share End --> */}

									</div>
								</div>
                                {/* <!-- .entry end --> */}

								{/* <!-- Post Navigation
								============================================= --> */}
								<div className="row justify-content-between col-mb-30 post-navigation">
								<div className="col-12 col-md-auto text-center">
										<a href="service">&lArr; Back to our services</a>
									</div>


									{/* <div className="col-12 col-md-auto text-center">
										<a href="#">This is an Embedded Audio Post &rArr;</a>
									</div> */}
								</div>


								{/* <!-- Comments
								============================================= --> */}
								<div id="comments" className="clearfix">



									<div className="clear"></div>

									{/* <!-- Comment Form
									============================================= --> */}

                                    {/* <!-- #respond end --> */}

								</div>
                                {/* <!-- #comments end --> */}

							</div>

						</div>
                        {/* <!-- .postcontent end --> */}



					</div>

				</div>
			</div>
		</section>



        </>
    )
}

export default Liability
