/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ReactDOM from 'react-dom';
// import { loadableReady } from '@loadable/component'
import './index.css';
import App from './App';



// loadableReady(() => {
//     const root = document.getElementById('wrapper')
//     hydrate(<App />, root)
//   })

 ReactDOM.render(<App />, document.getElementById('wrapper'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals

