/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../../App.css";
// import {Link} from 'react-router-dom';

function MotorVehicle() {
    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Motor Vehicle Insurance</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Service</li>

    </ol> */}

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row gutter-40 col-mb-80">
						{/* <!-- Post Content
						============================================= --> */}
						<div className="postcontent col-lg-12">

							<div className="single-post mb-0">

								{/* <!-- Single Post
								============================================= --> */}
								<div className="entry clearfix">

									{/* <!-- Entry Title
									============================================= --> */}
									{/* <div className="entry-title">
										<h2>This is a Standard post with a Preview Image</h2>
									</div> */}
                                    {/* <!-- .entry-title end --> */}

									{/* <!-- Entry Meta
									============================================= --> */}
									{/* <div className="entry-meta">
										<ul>
											<li><i className="icon-calendar3"></i> 10th July 2021</li>
											<li><a href="#"><i className="icon-user"></i> admin</a></li>
											<li><i className="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="#"><i className="icon-comments"></i> 43 Comments</a></li>
											<li><a href="#"><i className="icon-camera-retro"></i></a></li>
										</ul>
									</div> */}
                                    {/* <!-- .entry-meta end --> */}

									{/* <!-- Entry Image
									============================================= --> */}
									<div className="entry-image">
										<a href="#"><img src="/assets/images/custom/pages/banner/07_MOTOR_VEHICLE_INSURANCE.jpg" alt="Blog Single"/></a>
									</div>
                                    {/* <!-- .entry-image end --> */}

									{/* <!-- Entry Content
									============================================= --> */}
									<div className="entry-content mt-0">

										<p>Insurance that covers loss and or damage to the Motor Vehicle and or the insured interest which is directly caused by a collision, collision, overturning, slipping / falling, other people's evil deeds, theft and fire.
											Motor Vehicle Insurance Guarantee, including:</p>

										<h3>ALL RISKS / COMPREHENSIVE INSURANCE</h3>
										<p>All risk car insurance provides guarantees for compensation or repair costs for partial or total loss/damage to vehicles that are directly caused by collisions, collisions, overturning, slipping, falling, evil deeds, theft, fire, or other traffic accidents. (referring to PSAKBI)</p>

										{/* <blockquote><p>Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.</p></blockquote> */}
										<h3>TOTAL LOSS ONLY (TLO)</h3>
										<p>Compensation guarantee for total loss/damage to vehicles that are directly caused by car fires, cars experiencing accidents such as car crashes, collisions, overturning, slipping, falling, evil deeds, theft, or other traffic accidents. (referring to PSAKBI)</p>


										<p>EXTENDED WARRANTY INCLUDE :</p>

										<h4>A. THIRD PARTY LIABILITY (TPL)</h4>
										<p>Guaranteed compensation for claims of third parties, which are directly caused by the insured vehicle.</p>

										<h4>B. PERSONAL ACCIDENT (PA)</h4>
										<p>Coverage against death, bodily injury resulting in permanent disability and or medical expenses for the driver and/or passengers in the insured vehicle in the event of an accident.</p>

										<h4>C. TYPHON, STORM FLOOD, HAIL &amp; LANDSLIDE (TSFHL)</h4>
										<p>Guaranteed compensation or repair costs against damage to vehicles caused directly by hurricanes, storms, hail, floods, puddles or landslides.</p>

										<h4>D. EARTHQUAKE, TSUNAMI, &amp; VOLCANIC ERUPTION (EQVET)</h4>
										<p>Guaranteed compensation or repair costs for damage to vehicles caused directly by an earthquake, tsunami or volcanic eruption.</p>

										<h4>E. STRIKE, RIOT &amp; CIVIL COMMOTION (SRCC)</h4>
										<p>Guarantee for compensation or repair costs for damage to vehicles caused by riots, strikes, obstruction of work, brawls, riots, people's awakening or revolution.</p>

										<h4>F. TERRORISM &amp; SABOTAGE (TS)</h4>
										<p>Guaranteed compensation or repair costs for damage to vehicles caused by terrorism &amp; sabotage.</p>

										{/* <!-- Post Single - Content End --> */}

										{/* <!-- Tag Cloud
										============================================= --> */}
										<div className="tagcloud clearfix bottommargin">
											<a href="#">insurance</a>
											<a href="#">motor</a>
											<a href="#">general</a>
											<a href="#">vehicle</a>
											<a href="#">all-risk</a>
											<a href="#">comprehensive</a>
										</div>
                                        {/* <!-- .tagcloud end --> */}

										<div className="clear"></div>

										{/* <!-- Post Single - Share
										============================================= --> */}
										{/* <div className="si-share border-0 d-flex justify-content-between align-items-center">
											<span>Share this Post:</span>
											<div>
												<a href="#" className="social-icon si-borderless si-facebook">
													<i className="icon-facebook"></i>
													<i className="icon-facebook"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-twitter">
													<i className="icon-twitter"></i>
													<i className="icon-twitter"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-pinterest">
													<i className="icon-pinterest"></i>
													<i className="icon-pinterest"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-gplus">
													<i className="icon-gplus"></i>
													<i className="icon-gplus"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-rss">
													<i className="icon-rss"></i>
													<i className="icon-rss"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-email3">
													<i className="icon-email3"></i>
													<i className="icon-email3"></i>
												</a>
											</div>
										</div> */}
                                        {/* <!-- Post Single - Share End --> */}

									</div>
								</div>
                                {/* <!-- .entry end --> */}

								{/* <!-- Post Navigation
								============================================= --> */}
								<div className="row justify-content-between col-mb-30 post-navigation">
									<div className="col-12 col-md-auto text-center">
										<a href="service">&lArr; Back to our services</a>
									</div>

									{/* <div className="col-12 col-md-auto text-center">
										<a href="#">This is an Embedded Audio Post &rArr;</a>
									</div> */}
								</div>


								{/* <!-- Comments
								============================================= --> */}
								<div id="comments" className="clearfix">



									<div className="clear"></div>

									{/* <!-- Comment Form
									============================================= --> */}

                                    {/* <!-- #respond end --> */}

								</div>
                                {/* <!-- #comments end --> */}

							</div>

						</div>
                        {/* <!-- .postcontent end --> */}


					</div>

				</div>
			</div>
		</section>


        </>
    )
}

export default MotorVehicle
