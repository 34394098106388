/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../../App.css";
// import {Link} from 'react-router-dom';

function Engineering() {
    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Engineering Insurance</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Service</li>

    </ol> */}

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row gutter-40 col-mb-80">
						{/* <!-- Post Content
						============================================= --> */}
						<div className="postcontent col-lg-12">

							<div className="single-post mb-0">

								{/* <!-- Single Post
								============================================= --> */}
								<div className="entry clearfix">

									{/* <!-- Entry Title
									============================================= --> */}
									{/* <div className="entry-title">
										<h2>This is a Standard post with a Preview Image</h2>
									</div> */}
                                    {/* <!-- .entry-title end --> */}

									{/* <!-- Entry Meta
									============================================= --> */}
									{/* <div className="entry-meta">
										<ul>
											<li><i className="icon-calendar3"></i> 10th July 2021</li>
											<li><a href="#"><i className="icon-user"></i> admin</a></li>
											<li><i className="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="#"><i className="icon-comments"></i> 43 Comments</a></li>
											<li><a href="#"><i className="icon-camera-retro"></i></a></li>
										</ul>
									</div> */}
                                    {/* <!-- .entry-meta end --> */}

									{/* <!-- Entry Image
									============================================= --> */}
									<div className="entry-image">
										<a href="#"><img src="/assets/images/custom/pages/banner/10_ENGINEERING_INSURANCE.jpg" alt="Blog Single"/></a>
									</div>
                                    {/* <!-- .entry-image end --> */}

									{/* <!-- Entry Content
									============================================= --> */}
									<div className="entry-content mt-0">

										<p>Insurance that covers damage or loss due to material damage and liability to third parties during the construction or erection period, as well as damage or loss to machine or electronic equipment. This insurance consists of:</p>

										<h4>CONTRACTOR’S ALL RISK INSURANCE (CAR)</h4>
										<p>Provide guarantees for damage or loss of the insured object at the time of construction and during the maintenance period. In addition, guarantees are also provided for legal liability to third parties during the construction activities. Example: construction of roads, bridges, buildings, etc.</p>

										{/* <blockquote><p>Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.</p></blockquote> */}
										<h4>ERECTION ALL RISK INSURANCE (EAR)</h4>
										<p>Provide guarantees for damage or loss of the insured object at the time of installation, construction, testing and during the maintenance period. In addition, guarantees are also provided for legal liability to third parties during the construction installation activities. Example: factory machine installation, antenna installation, etc.</p>

										<h4>ELECTRONIC EQUIPMENT INSURANCE (EEI)</h4>
										<p>Provides coverage for damage or loss of electronic equipment due to hazards that come from outside, such as short circuit, fire, etc. Example: insurance for television studio equipment, CCTV, telecommunications equipment, laptops etc. This insurance can also be extended for the risk of losing movable electronic equipment.</p>

										<h4>MACHINERY BREAKDOWN INSURANCE</h4>
										<p>Provide guarantees for damage or loss to machines that are damaged or stop operating caused by damage to the machine itself (internal) and not from danger that comes from outside (external). Example: insurance for factory machines, refrigerators, generators, etc</p>

										<h4>CIVIL ENGINEERING COMPLETED RISKS (C.E.C.R) INSURANCE </h4>
										<p>Provide guarantees for completed civil works such as bridges, dams, ports, and other civil structures. The guarantees provided by this policy include damage due to fire, earthquake, flood, landslide, storm, etc</p>

										<h4>CONTRACTOR PLAN &amp; MACHINERY (CPM) </h4>
										<p>Provide coverage for damage or loss to heavy equipment used caused by the danger of collision, overturning, theft, natural disasters, etc.
										Example: insurance for tractors, excavators, bulldozers, etc.</p>
										{/* <!-- Post Single - Content End --> */}

										{/* <!-- Tag Cloud
										============================================= --> */}
										<div className="tagcloud clearfix bottommargin">
											<a href="#">insurance</a>
											<a href="#">casualty</a>
											<a href="#">general</a>
											<a href="#">engineering</a>
											<a href="#">civil</a>
											<a href="#">contractors</a>
										</div>
                                        {/* <!-- .tagcloud end --> */}

										<div className="clear"></div>

										{/* <!-- Post Single - Share
										============================================= --> */}
										{/* <div className="si-share border-0 d-flex justify-content-between align-items-center">
											<span>Share this Post:</span>
											<div>
												<a href="#" className="social-icon si-borderless si-facebook">
													<i className="icon-facebook"></i>
													<i className="icon-facebook"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-twitter">
													<i className="icon-twitter"></i>
													<i className="icon-twitter"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-pinterest">
													<i className="icon-pinterest"></i>
													<i className="icon-pinterest"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-gplus">
													<i className="icon-gplus"></i>
													<i className="icon-gplus"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-rss">
													<i className="icon-rss"></i>
													<i className="icon-rss"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-email3">
													<i className="icon-email3"></i>
													<i className="icon-email3"></i>
												</a>
											</div>
										</div> */}
                                        {/* <!-- Post Single - Share End --> */}

									</div>
								</div>
                                {/* <!-- .entry end --> */}

								{/* <!-- Post Navigation
								============================================= --> */}
								<div className="row justify-content-between col-mb-30 post-navigation">
								<div className="col-12 col-md-auto text-center">
										<a href="service">&lArr; Back to our services</a>
									</div>


									{/* <div className="col-12 col-md-auto text-center">
										<a href="#">This is an Embedded Audio Post &rArr;</a>
									</div> */}
								</div>


								{/* <!-- Comments
								============================================= --> */}
								<div id="comments" className="clearfix">



									<div className="clear"></div>


								</div>
                                {/* <!-- #comments end --> */}

							</div>

						</div>
                        {/* <!-- .postcontent end --> */}

					</div>

				</div>
			</div>
		</section>



        </>
    )
}

export default Engineering
