import React from 'react'

function Footer() {
    const bgColor = {
        backgroundColor: "#F5F5F5",
        borderTop: "2px solid rgba(0,0,0,0.06)"
      };

    //   const borderBot = {
    //     borderBottom: "1px solid rgba(0,0,0,0.06)"
    //   };

    //   const marginr = {
    //     marginRight: "10px"
    //   };

    //   const disBlock = {
    //     display: "block",
    //     marginTop: "3px"
    //   };

      const pTop = {
        paddingTop: "8px"
      };

      const imgStyle = {
        marginTop: "8px",
        paddingRight: "18px",
        // borderRight: "1px solid #4A4A4A",
        maxWidth: "200px"
      }


    return (
        <footer id="footer" style={bgColor}>

<div className="container">
				{/* <!-- Footer Widgets
				============================================= --> */}
				<div className="footer-widgets-wrap">

					<div className="row col-mb-50">
                    <div className="col-md-4">
							<div className="widget clearfix">
								<h4 className="center">Registered</h4>
								<div className="col" style={pTop}>
                                 <div className="row widget_links">
                                     <img src="assets/images/custom/footer/OJK.png" className="rounded mx-auto d-block" alt="Clients"/>
                                 </div>
                                 <div className="row widget_links">
                                     <img src="assets/images/custom/footer/MariBerasuransi.png" className="rounded mx-auto d-block" alt="Clients"/>
                                 </div>
                                <div className="row widget_links">
                                     <img src="assets/images/custom/footer/APPARINDO.png" className="rounded mx-auto d-block" alt="Clients"/>
                                 </div>

                                 </div>
							</div>
						</div>
                        <div className="col-md-3"></div>
						<div className="col-md-5">
							<div className="widget clearfix">

                            <h4>Contact Us</h4>

								{/* <div className="line line-sm"></div> */}

								<div className="row">
                                <img src="assets/images/logo.png" alt="assets/images/logo.png" className="alignleft" style={imgStyle}/>
                <div className="col-lg-12">

                    <div className="footer">


                        <p>
                        <span className="icon"><i className="icon-line-map-pin"></i>Grand Slipi Tower 16th Floor</span>
                        <p style={{marginLeft:"30px", marginBottom:"0"}}>
                        Jl. Letjen S. Parman Kav. 22-24 Jakarta Barat 11480</p>
                        <i className="icon-phone"></i>(+62) 21 2902 2561<br/>
                        <i className="icon-mail"></i>customer.care@raysbrokers.id<br/>
                        {/* <i className="icon-time"></i>08:00-19:00| Senin s/d Sabtu<br/> */}

                        </p>

                    </div>

                </div>

                <div className="col-lg-12">

                    {/* <div className="footer">

                        <span>No Tel:</span>

                        <p>(+62) 21 2902 2561</p>

                    </div> */}

                </div>


                <div className="col-lg-12">

                    {/* <div className="footer">

                        <span>Email:</span>

                        <p>customer.service@raysbroker.id</p>

                    </div> */}

                </div>

            </div>

							</div>
						</div>


					</div>

				</div>
                {/* <!-- .footer-widgets-wrap end --> */}
			</div>





        <div id="copyrights" className="bg-transparent">

            <div className="container clearfix">



                <div className="row col-mb-30">

                    <div className="col-md-6 text-center text-md-left">

                        Copyrights &copy; 2021 All Rights Reserved by Rays Solutions.<br/>

                        <div className="copyright-links"><a href="/">Terms of Use</a> / <a href="/">Privacy Policy</a>/<a href="/">Download Company Profile</a></div>

                    </div>



                    <div className="col-md-6 text-center text-md-right">

                        <div className="copyrights-menu copyright-links clearfix">

                            {/* <a href="/">Home</a>/<a href="/About">About Us</a>/<a href="/Service">Services</a>/<a href="/">Clients</a>/<a href="/">FAQs</a>/<a href="/Contact">Contact</a> */}

                        </div>

                    </div>

                </div>



            </div>

        </div>
        {/* <!-- #copyrights end --> */}

    </footer>
    )
}

export default Footer
