/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import {
	Link
  } from "react-router-dom";



function Service() {


    //   const bgColor1a = {
    //     backgroundColor: "#4460A1"
    //   };

    //   const bgColor34 = {
    //     backgroundColor: "#4C6BB2"
    //   };

    //   const bgColorDE = {
    //     backgroundColor: "#5D79BA"
    //   };


    //   const lHeight = {
    //     lineHeight: "1.8"
    //   };

    //   const fWeight = {
    //     fontWeight: "600"
    //   }

	//   let { path, url } = useRouteMatch();

    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Our Services</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Departments</li>

    </ol> */}

</div>



</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="grid-filter-wrap">

						{/* <!-- Portfolio Filter
						============================================= --> */}
						<ul className="grid-filter" data-container="#portfolio">
							<li className="activeFilter"><a href="#" data-filter="*">Show All</a></li>
							<li><a href="#" data-filter=".pf-employee">Employee Benefits</a></li>
							<li><a href="#" data-filter=".pf-general">General Insurance</a></li>
							{/* <li><a href="#" data-filter=".pf-uielements">UI Elements</a></li>
							<li><a href="#" data-filter=".pf-media">Media</a></li>
							<li><a href="#" data-filter=".pf-graphics">Graphics</a></li> */}
						</ul>
						{/* <!-- .grid-filter end --> */}

						{/* <div className="grid-shuffle rounded" data-container="#portfolio">
							<i className="icon-random"></i>
						</div> */}

					</div>

					{/* <!-- Portfolio Items
					============================================= --> */}
					<div id="portfolio" className="portfolio row grid-container gutter-20" data-layout="fitRows">

					<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-employee">
							<div className="grid-inner">
								<div className="portfolio-image">
								<a href="invidual">
										<img src="assets/images/custom/pages/InvidualHealth.jpg" alt="InvidualHealth"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/2.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/group-life" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/group-life">Invidual &amp; Family Health Insurance</Link></h3> */}
									{/* <span><a href="#">Illustrations</a></span> */}
								</div>
							</div>
						</article>

						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-employee">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="group-health">
										<img src="assets/images/custom/pages/02_GROUP_HEALTH_INSURANCE.jpg" alt="InvidualHealth"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/2.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/group-life" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/group-medical">Group Health Insurance</Link></h3> */}
									{/* <span><a href="#">Illustrations</a></span> */}
								</div>
							</div>
						</article>


						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-employee">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="personal-accident">
										<img src="assets/images/custom/pages/03_GROUP_PERSONAL_ACCIDENT.jpg" alt="Locked Steel Gate"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/2.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/personal-accident" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/personal-accident">Group Personal Accident Insurance</Link></h3> */}
									{/* <span><a href="#">Illustrations</a></span> */}
								</div>
							</div>
						</article>

						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-employee">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="group-life">
										<img src="assets/images/custom/pages/04_GROUP_TERM_LIFE.jpg" alt="Locked Steel Gate"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/2.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/personal-accident" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/group-life">Group Term Life</Link></h3> */}
									{/* <span><a href="#">Illustrations</a></span> */}
								</div>
							</div>
						</article>


						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-employee">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="travel">
										<img src="assets/images/custom/pages/05_TRAVEL_INSURANCE.jpg" alt="Console Activity"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/5.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/travel" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/travel">Travel Insurance</Link></h3> */}
									{/* <span><a href="#">UI Elements</a>, <a href="#">Media</a></span> */}
								</div>
							</div>
						</article>


						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-employee">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="tpa">
										<img src="assets/images/custom/pages/06_THIRD_PARTY_ADMINISTRATOR.jpg" alt="Locked Steel Gate"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/2.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/tpa" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/tpa">Third Party Administration(TPA)</Link></h3> */}
									{/* <span><a href="#">Illustrations</a></span> */}
								</div>
							</div>
						</article>



						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="motor-vehicle">
										<img src="assets/images/custom/pages/07_MOTOR_VEHICLE_INSURANCE.jpg" alt="Study Table"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="https://vimeo.com/91973305" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="iframe"><i className="icon-line-play"></i></a>
											<Link to="/motor-vehicle" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/motor-vehicle">Motor Vehicle Insurance</Link></h3> */}
									{/* <span><a href="#">Graphics</a>, <a href="#">Media</a></span> */}
								</div>
							</div>
						</article>

						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="property">
										<img src="assets/images/custom/pages/08_PROPERTY_INSURANCE.jpg" alt="Backpack Contents"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="https://www.youtube.com/watch?v=kuceVNBTJio" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="iframe"><i className="icon-line-play"></i></a>
											<Link to="/property" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/property">Property Insurance/Fire Insurance</Link></h3> */}
									{/* <span><a href="#">UI Elements</a>, <a href="#">Icons</a></span> */}
								</div>
							</div>
						</article>


						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="heavy-equipment">
										<img src="assets/images/custom/pages/09_HEAVY_EQUIPMENT_INSURANCE.jpg" alt="Workspace Stuff"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/11.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/heavy-equipment" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/heavy-equipment">Heavy Equipment Insurance</Link></h3> */}
									{/* <span><a href="#">Media</a>, <a href="#">Icons</a></span> */}
								</div>
							</div>
						</article>

						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<Link to="/engineering">
										<img src="assets/images/custom/pages/10_ENGINEERING_INSURANCE.jpg" alt="Sunset Bulb Glow"/>
									</Link>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/full/8.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/engineering" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/engineering">Engeenering Insurance</Link></h3> */}
									{/* <span><a href="#">Graphics</a></span> */}
								</div>
							</div>
						</article>


						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="liability">
										<img src="assets/images/custom/pages/11_LIABILITY_INSURANCE.jpg" alt="Console Activity"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/4/9.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/liability" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/liability">Liability Insurance</Link></h3> */}
									{/* <span><a href="#">UI Elements</a>, <a href="#">Media</a></span> */}
								</div>
							</div>
						</article>

						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="marine">
										<img src="assets/images/custom/pages/12_MARINE_CARGO_INSURANCE.jpg" alt="Console Activity"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/4/9.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/marine" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/marine">Marine Insurance</Link></h3> */}
									{/* <span><a href="#">UI Elements</a>, <a href="#">Media</a></span> */}
								</div>
							</div>
						</article>

						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="surety">
										<img src="assets/images/custom/pages/13_SURETY_BOND.jpg" alt="Console casual"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/4/9.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/Surety" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/Surety">Surety Insurance</Link></h3> */}
									{/* <span><a href="#">UI Elements</a>, <a href="#">Media</a></span> */}
								</div>
							</div>
						</article>


						<article className="portfolio-item col-lg-3 col-md-4 col-sm-6 col-12 pf-general">
							<div className="grid-inner">
								<div className="portfolio-image">
									<a href="misc">
										<img src="assets/images/custom/pages/14_MISCELLANEOUS_INSURANCE.jpg" alt="Console Activity"/>
									</a>
									{/* <div className="bg-overlay">
										<div className="bg-overlay-content dark" data-hover-animate="fadeIn">
											<a href="assets/images/portfolio/4/9.jpg" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350" data-lightbox="image" title="Image"><i className="icon-line-plus"></i></a>
											<Link to="/Casualty" className="overlay-trigger-icon bg-light text-dark" data-hover-animate="fadeInDownSmall" data-hover-animate-out="fadeOutUpSmall" data-hover-speed="350"><i className="icon-line-ellipsis"></i></Link>
										</div>
										<div className="bg-overlay-bg dark" data-hover-animate="fadeIn"></div>
									</div> */}
								</div>
								<div className="portfolio-desc">
									{/* <h3><Link to="/Casualty">Casualty Insurance</Link></h3> */}
									{/* <span><a href="#">UI Elements</a>, <a href="#">Media</a></span> */}
								</div>
							</div>
						</article>




					</div>
					{/* <!-- #portfolio end --> */}

				</div>
			</div>
		</section>
		{/* <!-- #content end --> */}
        </>
    )
}

export default Service
