/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import ContactForm from '../ContactForm';

function Contact() {
    // const ColorFFF = {
    //     color: "#FFF"
    //   };

    //   const ColorEEE = {
    //     color: "#EEE"
    //   };

    // const zindex = {
    //     zIndex: 2
    //   };

    // const bgImage = {
    //     background: `url('assets/images/custom/contact-title.jpg') center 90%`,
    //     padding: "140px 0",
    //     backgroundSize : "cover"
    //   };

    //   const bgIndex = {
    //     background: "rgba(111,136,194,0.85)",
    //     zIndex: 1
    //   };

	  const gmap = {
		overflow: "hidden",
		background:"none !important",
		height:"500px",
		width:"550px"
	  }

	  const maprouter = {
		position: "relative",
		textAlign: "right",
		height: "500px",
		width: "550px"
	  }

    //   const bgColorf5 = {
    //     backgroundColor: "#f5f5f5"
    //   };

    //   const bgColor1a = {
    //     backgroundColor: "#4460A1"
    //   };

    //   const bgColor34 = {
    //     backgroundColor: "#4C6BB2"
    //   };

    //   const bgColorDE = {
    //     backgroundColor: "#5D79BA"
    //   };


    //   const lHeight = {
    //     lineHeight: "1.8"
    //   };

    //   const fWeight = {
    //     fontWeight: "600"
    //   }



    return (
        <>
	<section id="page-title" className="bg-image page-title-dark">
<div className="container clearfix">


    <h1>Contact Us</h1>

    {/* <span>Our service for our repected clients</span> */}

    <ol className="breadcrumb">

        {/* <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Departments</li> */}

    </ol>

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row align-items-stretch col-mb-50 mb-0">
						{/* <!-- Contact Form
						============================================= --> */}
						<div className="col-lg-6">

							<div className="fancy-title title-border">
								<h3>Send us an Email</h3>
							</div>

							<div className="">

								{/* <div className="form-result"></div> */}

								<ContactForm/>
							</div>

						</div>
						{/* <!-- Contact Form End --> */}

						{/* <!-- Google Map
						============================================= --> */}
						<div className="col-lg-6 min-vh-50">
						<div className="row footer">

						<div className="info-box">
							
                        <span className="icon"><i className="icon-line-map-pin"></i>Grand Slipi Tower 16th Floor</span>
                        <p style={{marginLeft:"30px", marginBottom:"0"}}>
                        Jl. Letjen S. Parman Kav. 22-24 Jakarta Barat 11480</p>
						</div>
						<div className="info-box">
                        <span className="icon"><i className="icon-phone3"></i></span>
						(+62) 21 2902 2561
						</div>
						<div className="info-box">
                        <span className="icon"><i className="icon-mail"></i></span>
						customer.care@raysbrokers.id
						</div>
						{/* <div className="info-box">
                        <span className="icon"><i className="icon-time"></i></span>
						08:00-19:00| Senin s/d Sabtu
						</div> */}
						</div>
						<div className="row">
						<div className="gmap h-100" style = {maprouter}><div className="gmap_canvas" style={gmap}><iframe title="map_location" width="550" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=Grand%20Slipi%20Tower&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=&amp;output=embed" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe></div></div>
						</div>


						</div>
						{/* <!-- Google Map End --> */}
					</div>

					{/* <!-- Contact Info
					============================================= --> */}
					{/* <div className="row col-mb-50">
						<div className="col-md-4 col-sm-6 col-12">
							<div className="feature-box fbox-center fbox-bg fbox-plain">
								<div className="fbox-icon">
									<a href="#"><i className="icon-map-marker2"></i></a>
								</div>
								<div className="fbox-content">
									<h3>Our Headquarters<span className="subtitle">Grand Slipi Tower 16th Floor<br/>
						Jl. Letjen S. Parman Kav 22-24<br/>
						Jakarta Barat 11480</span></h3>
								</div>
							</div>
						</div>

						<div className="col-md-4 col-sm-6 col-12">
							<div className="feature-box fbox-center fbox-bg fbox-plain">
								<div className="fbox-icon">
									<a href="#"><i className="icon-phone3"></i></a>
								</div>
								<div className="fbox-content">
									<h3>Speak to Us<span className="subtitle">(+62)21-2902-2561</span></h3>
								</div>
							</div>
						</div>

						<div className="col-md-4 col-sm-6 col-12">
							<div className="feature-box fbox-center fbox-bg fbox-plain">
								<div className="fbox-icon">
									<a href="#"><i className="icon-mail"></i></a>
								</div>
								<div className="fbox-content">
									<h3>Email<span className="subtitle">customer.care@ raysbrokers.id</span></h3>
								</div>
							</div>
						</div>

						<div className="col-md-4 col-sm-6 col-12">
							<div className="feature-box fbox-center fbox-bg fbox-plain">
								<div className="fbox-icon">
									<a href="#"><i className="icon-twitter2"></i></a>
								</div>
								<div className="fbox-content">
									<h3>Follow on Twitter<span className="subtitle">2.3M Followers</span></h3>
								</div>
							</div>
						</div>
					</div> */}
					{/* <!-- Contact Info End --> */}

				</div>
			</div>
		</section>
		{/* <!-- #content end --> */}


        </>
    );
}

export default Contact
