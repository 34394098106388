/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../../App.css";
// import {Link} from 'react-router-dom';

function Surety() {
    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Surety Bonds</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Service</li>

    </ol> */}

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row gutter-40 col-mb-80">
						{/* <!-- Post Content
						============================================= --> */}
						<div className="postcontent col-lg-12">

							<div className="single-post mb-0">

								{/* <!-- Single Post
								============================================= --> */}
								<div className="entry clearfix">

									{/* <!-- Entry Title
									============================================= --> */}
									{/* <div className="entry-title">
										<h2>This is a Standard post with a Preview Image</h2>
									</div> */}
                                    {/* <!-- .entry-title end --> */}

									{/* <!-- Entry Meta
									============================================= --> */}
									{/* <div className="entry-meta">
										<ul>
											<li><i className="icon-calendar3"></i> 10th July 2021</li>
											<li><a href="#"><i className="icon-user"></i> admin</a></li>
											<li><i className="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="#"><i className="icon-comments"></i> 43 Comments</a></li>
											<li><a href="#"><i className="icon-camera-retro"></i></a></li>
										</ul>
									</div> */}
                                    {/* <!-- .entry-meta end --> */}

									{/* <!-- Entry Image
									============================================= --> */}
									<div className="entry-image">
										<a href="#"><img src="/assets/images/custom/pages/banner/13_SURETY_BOND.jpg" alt="Blog Single"/></a>
									</div>
                                    {/* <!-- .entry-image end --> */}

									{/* <!-- Entry Content
									============================================= --> */}
									<div className="entry-content mt-0">

										<p>A form of guarantee requested by the obligee (project owner) to the principal (project implementer) or the insured with a view to stating the principal's ability to carry out his work in accordance with the contract/agreement.</p>

										<p>Types of Surety Bond guarantees include:</p>

										<h4>1. BID BOND</h4>
										<p>Bid Guarantee is a bid bond, which is a written commitment given by the bank to the recipient of the guarantee that the bank will pay a sum of money to him if the guaranteed party does not participate in the auction and does not close the charter contract in the event that the bid is accepted.
											The amount of the Bid Guarantee is 1% - 3% of the Contract value</p>

										<h4>2. PERFORMANCE BOND</h4>
										<p>Performance Guarantee is a letter that functions to guarantee the implementation of the work in accordance with the contract, the provider must provide a guarantee for the implementation of the PPK at the time after the issuance of the SPPBJ and before signing the Contract for the Procurement of Goods/Construction Work/Other Services, with a validity period from the date of the contract until the handover of the goods/services. Others or first handover of Construction Works.
										The amount of the Performance Guarantee is 5% of the Contract value.</p>

										{/* <blockquote><p>Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.</p></blockquote> */}

										<h4>3. ADVANCE BOND</h4>
										<p>Advance Payment Guarantee or Advance Payment Bond issued by the Surety Company to guarantee the Obligee that the Principal will be able to return the advance he has received from the Obligee in accordance with the terms agreed in the contract, with a view to facilitating project financing.
										The amount of the Advance Payment Guarantee is a certain percentage of the value of the project contract itself, which is 20% – 30% of the project contract value</p>

										<h4>4. MAINTENANCE BOND</h4>
										<p>Maintenance Guarantee is a guarantee letter that functions to guarantee the implementation of Maintenance Work provided by providers of Construction Works or Other Procurement Services that require a maintenance period in requesting 100% payment (Maintenance Guarantee is not used in Procurement of Goods and Consulting Services), with a validity period starting from the work maintenance up to 14 (fourteen) working days after the maintenance period is over.
										The amount of the Maintenance Guarantee value is 5% of the Contract value.</p>


										{/* <!-- Post Single - Content End --> */}

										{/* <!-- Tag Cloud
										============================================= --> */}
										<div className="tagcloud clearfix bottommargin">
											<a href="#">insurance</a>
											<a href="#">bid</a>
											<a href="#">general</a>
											<a href="#">bond</a>
											<a href="#">performance</a>
											<a href="#">maintenance</a>
											<a href="#">advance</a>
										</div>
                                        {/* <!-- .tagcloud end --> */}

										<div className="clear"></div>

										{/* <!-- Post Single - Share
										============================================= --> */}
										{/* <div className="si-share border-0 d-flex justify-content-between align-items-center">
											<span>Share this Post:</span>
											<div>
												<a href="#" className="social-icon si-borderless si-facebook">
													<i className="icon-facebook"></i>
													<i className="icon-facebook"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-twitter">
													<i className="icon-twitter"></i>
													<i className="icon-twitter"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-pinterest">
													<i className="icon-pinterest"></i>
													<i className="icon-pinterest"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-gplus">
													<i className="icon-gplus"></i>
													<i className="icon-gplus"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-rss">
													<i className="icon-rss"></i>
													<i className="icon-rss"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-email3">
													<i className="icon-email3"></i>
													<i className="icon-email3"></i>
												</a>
											</div>
										</div> */}
                                        {/* <!-- Post Single - Share End --> */}

									</div>
								</div>
                                {/* <!-- .entry end --> */}

								{/* <!-- Post Navigation
								============================================= --> */}
								<div className="row justify-content-between col-mb-30 post-navigation">
								<div className="col-12 col-md-auto text-center">
										<a href="service">&lArr; Back to our services</a>
									</div>


									{/* <div className="col-12 col-md-auto text-center">
										<a href="#">This is an Embedded Audio Post &rArr;</a>
									</div> */}
								</div>


								{/* <!-- Comments
								============================================= --> */}
								<div id="comments" className="clearfix">



									<div className="clear"></div>

									{/* <!-- Comment Form
									============================================= --> */}
								    {/* <!-- #respond end --> */}

								</div>
                                {/* <!-- #comments end --> */}

							</div>

						</div>
                        {/* <!-- .postcontent end --> */}



					</div>

				</div>
			</div>
		</section>


        </>
    )
}

export default Surety
