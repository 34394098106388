/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';

function Banner() {

		//   const bgColor = {
		// 	backgroundColor: "rgba(0,0,0,0.55)"
		//   };

		//   const slide1 = {
		// 	backgroundImage: `url('assets/demos/medical/images/slider/3.jpg')`
		//   };

		//   const slide2 = {
		// 	backgroundImage: `url('assets/demos/medical/images/slider/1.jpg')`
		//   };
return(
<>

<section id="slider" className="slider-element boxed-slider">

			{/* <div className="container"> */}

				<div id="oc-slider" className="owl-carousel carousel-widget" data-margin="0" data-items="1" data-animate-in="zoomIn" data-speed="450" data-animate-out="fadeOut">

					<a href="#"><img src="assets/demos/medical/images/slider/9.jpg" alt="Slider"/></a>
					<a href="#"><img src="assets/demos/medical/images/slider/10.jpg" alt="Slider"/></a>
					<a href="#"><img src="assets/demos/medical/images/slider/11.jpg" alt="Slider"/></a>

				</div>

			{/* </div> */}

		</section>


{/* <section id="page-title" className="page-title-dark page-title-center p-0">



<div className="fslider" data-arrows="false" data-pagi="false" data-animation="fade" data-hover="false">

    <div className="flexslider">



        <div className="slider-wrap">

            <div className="slide"><img src="assets/demos/medical/images/slider/1.jpg" alt="Page Title Image"/></div>

            <div className="slide"><img src="assets/demos/medical/images/slider/2.jpg" alt="Page Title Image"/></div>

            <div className="slide"><img src="assets/demos/medical/images/slider/8.jpg" alt="Page Title Image"/></div>

            <div className="slide"><img src="assets/demos/medical/images/about-us/page-title/4.jpg" alt="Page Title Image"/></div>

        </div>



        <div className="vertical-middle vertical-middle-overlay">



        </div>



    </div>

</div>



</section> */}
</>
);
	}


export default Banner;


