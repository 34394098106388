/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../../App.css";
// import {Link} from 'react-router-dom';

function Property() {
    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Property Insurance / FIRE INSURANCE</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Service</li>

    </ol> */}

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row gutter-40 col-mb-80">
						{/* <!-- Post Content
						============================================= --> */}
						<div className="postcontent col-lg-12">

							<div className="single-post mb-0">

								{/* <!-- Single Post
								============================================= --> */}
								<div className="entry clearfix">

									{/* <!-- Entry Title
									============================================= --> */}
									{/* <div className="entry-title">
										<h2>PROPERTY INSURANCE / FIRE INSURANCE</h2>
									</div> */}
                                    {/* <!-- .entry-title end --> */}

									{/* <!-- Entry Meta
									============================================= --> */}
									{/* <div className="entry-meta">
										<ul>
											<li><i className="icon-calendar3"></i> 10th July 2021</li>
											<li><a href="#"><i className="icon-user"></i> admin</a></li>
											<li><i className="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="#"><i className="icon-comments"></i> 43 Comments</a></li>
											<li><a href="#"><i className="icon-camera-retro"></i></a></li>
										</ul>
									</div> */}
                                    {/* <!-- .entry-meta end --> */}

									{/* <!-- Entry Image
									============================================= --> */}
									<div className="entry-image">
										<a href="#"><img src="/assets/images/custom/pages/banner/08_PROPERTY_INSURANCE.jpg" alt="Blog Single"/></a>
									</div>
                                    {/* <!-- .entry-image end --> */}

									{/* <!-- Entry Content
									============================================= --> */}
									<div className="entry-content mt-0">

										<p>A comprehensive insurance policy covers the risk of Flexas and almost all of its extensions including covering the risks that are categorized as accidental damage to buildings, contents, machinery and stock, except for the risks that are excluded in the policy.</p>

										<h4>FLEXAS INSURANCE</h4>

										<p>Covers the risks: Fire, Lightning, Explosion, plane crash and fire smoke (Flexas). The policy coverage can be extended to cover the risks: Riots, Strikes, Work Barriers and Riots, landslides, hurricanes and floods, landslides and several other risks.</p>

										<h4>PROPERTY ALL RISK / INDUSTRIAL ALL RISK POLICY</h4>
										<p>A comprehensive insurance policy covers the risk of Flexas and almost all of its extensions including covering the risks that are categorized as accidental damage to buildings, contents, machinery and stock, except for the risks that are excluded in the policy.</p>

										{/* <blockquote><p>Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.</p></blockquote> */}

										<h4>EARTHQUAKE INSURANCE</h4>
										<p>A policy that will complement the Flexas, Property All Risk or Industrial All Risk insurance coverage by covering loss or damage to property due to the risk of Earthquake, Tsunami and Volcanic Eruption.</p>

										<h4>BUSINESS INTERRUPTION INSURANCE </h4>
										<p>Provide compensation for financial losses or loss of profits caused by disruption or cessation of business activities due to the destruction of property used as business activities by a cause of risk guaranteed by the policy.</p>


										{/* <!-- Post Single - Content End --> */}

										{/* <!-- Tag Cloud
										============================================= --> */}
										<div className="tagcloud clearfix bottommargin">
											<a href="#">insurance</a>
											<a href="#">property</a>
											<a href="#">general</a>
											<a href="#">fire</a>
											<a href="#">earthquake</a>
										</div>
                                        {/* <!-- .tagcloud end --> */}

										<div className="clear"></div>

										{/* <!-- Post Single - Share
										============================================= --> */}
										{/* <div className="si-share border-0 d-flex justify-content-between align-items-center">
											<span>Share this Post:</span>
											<div>
												<a href="#" className="social-icon si-borderless si-facebook">
													<i className="icon-facebook"></i>
													<i className="icon-facebook"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-twitter">
													<i className="icon-twitter"></i>
													<i className="icon-twitter"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-pinterest">
													<i className="icon-pinterest"></i>
													<i className="icon-pinterest"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-gplus">
													<i className="icon-gplus"></i>
													<i className="icon-gplus"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-rss">
													<i className="icon-rss"></i>
													<i className="icon-rss"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-email3">
													<i className="icon-email3"></i>
													<i className="icon-email3"></i>
												</a>
											</div>
										</div> */}
                                        {/* <!-- Post Single - Share End --> */}

									</div>
								</div>
                                {/* <!-- .entry end --> */}

								{/* <!-- Post Navigation
								============================================= --> */}
								<div className="row justify-content-between col-mb-30 post-navigation">
								<div className="col-12 col-md-auto text-center">
										<a href="service">&lArr; Back to our services</a>
									</div>


									{/* <div className="col-12 col-md-auto text-center">
										<a href="#">This is an Embedded Audio Post &rArr;</a>
									</div> */}
								</div>


								{/* <!-- Comments
								============================================= --> */}
								<div id="comments" className="clearfix">



									<div className="clear"></div>

									{/* <!-- Comment Form
									============================================= --> */}
								   {/* <!-- #respond end --> */}

								</div>
                                {/* <!-- #comments end --> */}

							</div>

						</div>
                        {/* <!-- .postcontent end --> */}


					</div>

				</div>
			</div>
		</section>


        </>
    )
}

export default Property
