/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../App.css";

// import PageTitle from '../PageTitle';

function About() {
      const backgroundImg1 = {
        backgroundImage: `url('assets/demos/medical/images/about-us/counters/1.jpg') no-repeat center center `,
        backgroundSize : "cover"
      };

      const backgroundImg2 = {
        backgroundImage: `url('assets/demos/medical/images/about-us/counters/2.jpg') no-repeat center center `,
        backgroundSize : "cover"
      };

      const backgroundImg3 = {
        backgroundImage: `url('assets/demos/medical/images/about-us/counters/3.jpg') no-repeat center center `,
        backgroundSize : "cover"
      };

      const backgroundImg4 = {
        backgroundImage: `url('assets/demos/medical/images/about-us/counters/4.jpg') no-repeat center center `,
        backgroundSize : "cover"
      };

      const backgroundColor229 = {
        backgroundColor: "rgba(76, 107, 178, 0.8)"
      };

      const backgroundColor211 = {
        backgroundColor: "rgba(68, 96, 160, 0.8)"
      };

      const backgroundColor198 = {
        backgroundColor: "rgba(61, 86, 142, 0.8)"
      };

      const backgroundColor183 = {
        backgroundColor: "rgba(53, 75, 124, 0.8)"
      };

	  const backgroundColor47 = {
        backgroundColor: "rgba(47, 67, 111, 0.8)"
      };

      const backgroundColorSp = {
        backgroundColor: "rgba(238,238,238,0.9)",
        position: "absolute",
        top: "0",
        left: "0",
        zIndex: "1",
        height: "100%",
        width: "100%"

      };



      const backgroundAbout = {
         //background: `#FFF url('assets/demos/medical/images/about-us/1.jpg') right center no-repeat`,
		background: `#FFF url('assets/images/custom/about-image-2.jpg') right center no-repeat`		// backgroundSize : "cover"
      };




    return (
        <>
<section id="page-title" className="bg-image page-title-dark">
<div className="container clearfix">

    <h1>About Us</h1>

    {/* <span>Our service for our repected clients</span> */}



</div>
</section>
<section id="content">

			<div className="content-wrap">

				<div className="container clearfix">


					<h2 className="center">COMPANY BACKGROUND</h2>

					<p>
					Since the beginning of our establishment, we are determined to focus on service to clients because our founder and executive team are very aware that this is the most important thing to gain trust from clients.We start with with understanding our client’s needs and then finding the right solution. However We go beyond the point of just understanding what is the best for the client and keep looking for insurance solutions that trully benefit our customers. We don’t stop after signing the contract but are always on the lookout for improvement.

					</p>
					<p>
					We also have a highly respected reputation throughout the insurance industry and are regarded as being at the leading edge of our profssion in terms of innovation, product development and servicing standards.
					</p>

					<p>
					We earn the trust of the Financial Services Authority (Otoritas Jasa Keuangan / OJK) to operate as a Licensed Insurance Broker under the new name PT Rays Solusi Broker Asuransi (RAYS SOLUTIONS) on February 26, 2021 after acquiring an insurance broker company, PT Proteksi Indonesia with deed of establishment 18 June 2015 through operating license No KEP-517/NB.1/2015.

					</p>

					<p>
					For us this is only the beginning of our story and we will continue to be reliable partner with an ever stronger value proposition.

					</p>

					<br/>

				</div>
				<div className="container clearfix">
				{/* <h2 className="center">VISION MISSION</h2> */}
				<div className="row col">
				<div className="col-md-6" style={{textAlign: 'justify'}}>
				{/* <p style={{textAlign: 'left'}}><i className="icon-quote-left" style={{fontSize: '2rem'}}></i></p> */}
				<h2 className="center">VISION</h2>
				<p>Rays Solutions is committed to developing a culture that is distinctly different from our competitors. Our focus is not on building the largest company in the industry and benefits business, but on building the most respected and preffered Insurance Broker in Indonesia by offering creative and innovative solutions to meet the demands and challenges of today’s business. </p>

					</div>
					<div className="col-md-6" style={{textAlign: 'justify'}}>
					{/* <p style={{textAlign: 'right'}}><i className="icon-quote-right" style={{fontSize: '2rem'}}></i></p> */}

					<h2 className="center">MISSION</h2>
				<p>To provide the highest standard in Insurance protection and business service with a competitive pricing. We aim to build strong relationships with clients and our insurance partners whilst respecting their interests and values.</p>

					</div>

				</div>

					<br/>

				</div>

				<h2 className="center">CORE VALUE</h2>

				<div className="row counters clearfix mb-0 topmargin-sm align-items-stretch core-value">


					<div className="col-lg-3 col-md-6 dark text-center min-vh-55" style={backgroundImg1}>

						<div className="bg-overlay">

							<div className="bg-overlay-content overlay-custom dark">

								<div>

									{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-surgery"></i> */}

									<img src="assets/images/custom/Integrity.png" alt="Clients"/>

									{/* <div className="counter counter-lined"><span data-from="100" data-to="42762" data-refresh-interval="50" data-speed="2300"></span></div> */}

									<h3>Integrity</h3>

									{/* <div className="counter-lined"></div> */}

									<p>The foundation of everything we do is integrity by always keeping the highest  moral character. Integrity therefore always has a central place in every aspect of the process when working with people and their most precious assets</p>

								</div>

							</div>

							<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor229}></div>

						</div>

					</div>



					<div className="col-lg-3 col-md-6 dark text-center min-vh-55" style={backgroundImg2}>

						<div className="bg-overlay">

							<div className="bg-overlay-content overlay-custom dark">

								<div>

									{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-text-telephone"></i> */}

									<img src="assets/images/custom/Professional.png" alt="Clients"/>
									{/* <div className="counter counter-lined"><span data-from="3000" data-to="21500" data-refresh-interval="100" data-speed="2500"></span></div> */}

									<h3>Professionalism</h3>

									<p>Our professionalism is reflected by our competence and knowledge on risk solutions, accuracy but also efficiency in our work and by always being reasonable in our dealings with a continuous commitment to high quality</p>

								</div>

							</div>

							<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor211}></div>

						</div>

					</div>



					<div className="col-lg-3 col-md-6 dark text-center min-vh-55" style={backgroundImg3}>

						<div className="bg-overlay">

							<div className="bg-overlay-content overlay-custom dark">

								<div>

									{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-social-services"></i> */}

									<img src="assets/images/custom/team.png" alt="Clients"/>
									{/* <div className="counter counter-lined"><span data-from="20" data-to="408" data-refresh-interval="25" data-speed="3500"></span>K</div> */}

									<h3>Teamwork</h3>
									<p>For success, teamwork and collaboration is crucial. We see ourselves as equal partners in all our relationships, working towards the same goals</p>

								</div>

							</div>

							<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor198}></div>

						</div>

					</div>



					<div className="col-lg-3 col-md-6 dark text-center min-vh-55" style={backgroundImg4}>

						<div className="bg-overlay">

							<div className="bg-overlay-content overlay-custom dark">

								<div>

									{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-ambulance"></i> */}

									<img src="assets/images/custom/Innovation.png" alt="Clients"/>

									{/* <div className="counter counter-lined"><span data-from="60" data-to="140" data-refresh-interval="20" data-speed="2700"></span></div> */}

									<h3>Innovation</h3>

									<p>We value challenges because they push us towards innovations. We look at problems as challenges and approach them with enthusiasm and optimism. We are always  proactive rather than reactive and look for solutions that make us different and allow our partners tp shine </p>

								</div>

							</div>

							<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor183}></div>

						</div>

					</div>



				</div>



				<div className="section mt-0" style={backgroundAbout}>



					<div className="d-block d-md-block d-lg-none" style={backgroundColorSp}></div>



					<div className="container clearfix">



						<div className="row justify-content-between">

							<div className="col-lg-6" data-lightbox="gallery">

								<div className="heading-block border-bottom-0 bottommargin-sm">

									<h3 className="nott ls0">What We do Actually</h3>

								</div>

								<p>We are Insurance brokers, specialized in insurance protection and risk management, we serve clients with a first-className service based on our company’s standards, responsive, professional, and transparent to ensure that our clients meet their insurance and business needs.</p>
								<p>We negotiate with insurance underwriters on clients behalf to help them obtain the exact cover they need.</p>
								<p>We have access to a broad market of insurers that will be offered to our clients. We provide a comparison between multiple insurers to achieve the best value proposition, most competitive price along with the good benefits package and comprehensive Terms &amp; Conditions for our clients. We understand that our client’s time is valuable and aim to make the insurance process as efficient and painless as possible.When we have arranged our clients’ insurance, our work does not end. When it counts the most, we will stand in their side as claim advocates, ensuring to get what our clients are entitled to as quickly and efficiently as possible.</p>
								<p>We evaluate the risks at renewal time based on the claims experience to ensure that the coverage is sufficiently protected for the next renewal insurance period and get decent value for money.</p>
								{/* <div className="feature-box fbox-plain fbox-sm mb-4">

									<div className="fbox-icon mt-2" data-animate="fadeIn">

										<a href="#"><i className="icon-medical-i-womens-health"></i></a>

									</div>

									<div className="fbox-content">

										<p className="mt-0">We negotiate with insurance underwriters on clients behalf to help them obtain the exact cover they need.</p>

									</div>

								</div>



								<div className="feature-box fbox-plain fbox-sm mb-4">

									<div className="fbox-icon mt-2" data-animate="fadeIn">

										<a href="#"><i className="icon-medical-i-ultrasound"></i></a>

									</div>

									<div className="fbox-content">

										<p className="mt-0">We have access to a broad market of insurers that will be offered to our clients. We provide a comparison between multiple insurers to achieve the best value proposition</p>

									</div>

								</div>



								<div className="feature-box fbox-plain fbox-sm mb-4 bottommargin-sm">

									<div className="fbox-icon mt-2" data-animate="fadeIn">

										<a href="#"><i className="icon-medical-i-cath-lab"></i></a>

									</div>

									<div className="fbox-content">

										<p className="mt-0">We evaluate the risks at renewal time based on the claims experience to ensure that the coverage is sufficiently protected for the next renewal insurance period and get decent value for money.</p>

									</div>

								</div> */}

							</div>

							{/* <div className="col-lg-5">

								<div className="opening-table">

									<div className="heading-block bottommargin-sm border-bottom-0">

										<h4>Opening Hours</h4>

										<span>Please call us (+62)21-2902-2561 for more detail about our product</span>

									</div>

									<div className="time-table-wrap clearfix">

										<div className="row time-table">

											<h5 className="col-lg-5">Monday</h5>

											<span className="col-lg-7">8:00am - 17:00pm</span>

										</div>

										<div className="row time-table">

											<h5 className="col-lg-5">Tuesday</h5>

											<span className="col-lg-7">8:00am - 17:00pm</span>

										</div>

										<div className="row time-table">

											<h5 className="col-lg-5">Wednesday</h5>

											<span className="col-lg-7">8:00am - 17:00pm</span>

										</div>

										<div className="row time-table">

											<h5 className="col-lg-5">Thursday</h5>

											<span className="col-lg-7">8:00am - 17:00pm</span>

										</div>

										<div className="row time-table">

											<h5 className="col-lg-5">Friday</h5>

											<span className="col-lg-7">8:00am - 17:00pm</span>

										</div>

										<div className="row time-table">

											<h5 className="col-lg-5">Saturday</h5>

											<span className="col-lg-7">8:00am - 17:00pm</span>

										</div>

										<div className="row time-table">

											<h5 className="col-lg-5">Sunday</h5>

											<span className="col-lg-7 color font-weight-semibold">Closed</span>

										</div>

									</div>

								</div>

							</div> */}

						</div>



					</div>



				</div>

<div className="container-fluid" style={{marginBottom:"50px"}}>
				<h2 className="center">Why Choose Ray Solutions…</h2>

<div className="row d-flex counters clearfix mb-0 topmargin-sm align-items-stretch core-value">


	<div className="col-lg-1-5 col-lg-offset-1 col-md-6 dark text-center min-vh-60 blue-boxed" style={backgroundImg1}>

		<div className="bg-overlay">

			<div className="bg-overlay-content overlay-custom-choose dark">

				<div>

					{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-surgery"></i> */}

					<img src="assets/images/custom/wider2.png" alt="Clients"/>

					{/* <div className="counter counter-lined"><span data-from="100" data-to="42762" data-refresh-interval="50" data-speed="2300"></span></div> */}

					<h3>Winder Choice &amp; Value</h3>

					{/* <div className="counter-lined"></div> */}

					<p>We are able to offer coverage from a broad range of reputable insurers, making sure that the choice is in your best interest best fitting your coverage needs and at the best possible rates.</p>

				</div>

			</div>

			<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor229}></div>

		</div>

	</div>



	<div className="col-lg-1-5 col-md-6 dark text-center min-vh-50 blue-boxed " style={backgroundImg2}>

		<div className="bg-overlay">

			<div className="bg-overlay-content overlay-custom-choose dark ">

				<div>

					{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-text-telephone"></i> */}

					<img src="assets/images/custom/personal2.png" alt="Clients"/>
					{/* <div className="counter counter-lined"><span data-from="3000" data-to="21500" data-refresh-interval="100" data-speed="2500"></span></div> */}

					<h3>Personalized Service</h3>

					<p>We take an innovative approach to risk management, conducting ongoing research to inform our solutions and ensure they’re relevant to your industry and business.</p>

				</div>

			</div>

			<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor211}></div>

		</div>

	</div>



	<div className="col-lg-1-5 col-md-6 dark text-center min-vh-50 blue-boxed" style={backgroundImg3}>
blu
		<div className="bg-overlay">

			<div className="bg-overlay-content overlay-custom-choose dark">

				<div>

					{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-social-services"></i> */}

					<img src="assets/images/custom/focus2.png" alt="Clients"/>
					{/* <div className="counter counter-lined"><span data-from="20" data-to="408" data-refresh-interval="25" data-speed="3500"></span>K</div> */}

					<h3>Client Focused</h3>
					<p>Attention to detail and quality of work, along with years of professional experience, make us the best choice to partner with.</p>

				</div>

			</div>

			<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor198}></div>

		</div>

	</div>



	<div className="col-lg-1-5 col-md-6 dark text-center min-vh-50 blue-boxed" style={backgroundImg4}>

		<div className="bg-overlay">

			<div className="bg-overlay-content overlay-custom-choose dark">


				<div>

					{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-ambulance"></i> */}

					<img src="assets/images/custom/network2.png" alt="Clients"/>

					{/* <div className="counter counter-lined"><span data-from="60" data-to="140" data-refresh-interval="20" data-speed="2700"></span></div> */}

					<h3>Reliable Network</h3>

					<p>We value the relationships we have built with our Clients and Insurers. The base for these strong bonds is our commitment to professionalism and looking at it as one overall business partnership</p>

				</div>

			</div>

			<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor183}></div>

		</div>

	</div>


	<div className="col-lg-1-5 col-md-12 dark text-center min-vh-50 blue-boxed" style={backgroundImg4}>

		<div className="bg-overlay">

			<div className="bg-overlay-content overlay-custom-choose dark">


				<div>

					{/* <i className="i-plain i-xlarge mx-auto icon-medical-i-ambulance"></i> */}

					<img src="assets/images/custom/claim.png" alt="Clients"/>

					{/* <div className="counter counter-lined"><span data-from="60" data-to="140" data-refresh-interval="20" data-speed="2700"></span></div> */}

					<h3>Claim Advocacy</h3>

					<p>We have an excellent dedicated claims team with a strong track record of ensuring successful claims settlements honoring the insured's rights</p>

				</div>

			</div>

			<div className="bg-overlay-bg op-ts op-1" data-hover-animate="op-0" data-hover-animate-out="op-1" style={backgroundColor47}></div>

		</div>

	</div>


</div>
</div>

				<div className="container clearfix">

					<div className="heading-block center border-bottom-0">

						<h3>Meet our MANAGEMENT</h3>

						<span>We make sure that your Life are in Good Hands.</span>

					</div>



					<div id="oc-team" className="owl-carousel team-carousel carousel-widget" data-margin="30" data-nav="true" data-pagi="true" data-items-xs="1" data-items-sm="2" data-items-lg="3" data-items-xl="4">



						<div className="team">


						<div className="team-desc">

								<div className="team-title"><h4>Leska</h4><span>President Commissioner</span></div>

						</div>

							<div className="team-image">

								{/* <img src="assets/demos/medical/images/doctors/1.jpg" alt="Dr. John Doe"/> */}
							<p>Started her career since 1981 and with more than 40 years of experience in Insurance, especially in financing, she served as Finance Director in several leading insurance companies and Insurance Broker Companies. Her extensive experiences and capability will lead Rays Solutions to become a preferred Insurance Broker that adheres to the OJK’s and government’s rules.</p>
							</div>



						</div>



						<div className="team">


						<div className="team-desc">

								<div className="team-title"><h4>Rustiati Andrias</h4><span>Commissioner</span></div>

						</div>

							<div className="team-image">

								{/* <img src="assets/demos/medical/images/doctors/1.jpg" alt="Dr. John Doe"/> */}
							<p>Graduated from Institut Pertanian Bogor in 1989, she had worked for 30 years in several Insurance companies specialized in Health Insurance. Her good experience in Management would certainly bring Rays Solutions into a better and bigger Insurance Brokerage Company.
</p>
							</div>



						</div>



						<div className="team">


						<div className="team-desc">

								<div className="team-title"><h4>Dian Palupi Anggarawati</h4><span>President Director</span></div>

						</div>

							<div className="team-image">

								{/* <img src="assets/demos/medical/images/doctors/1.jpg" alt="Dr. John Doe"/> */}
							<p>Dian is a key part of the management team,  armed with Actuarial and Economic education background with more than 20 years experience in several Insurance companies, she held important positions in some Insurance Industry companies. Armed with excellent technical and marketing skills. She has been able and proven to make Rays Solutions advanced and get the trust of various clients from various industries.</p>
							</div>



						</div>



						<div className="team">


						<div className="team-desc">

								<div className="team-title"><h4>Melly Kohar</h4><span>Director</span></div>

						</div>

							<div className="team-image">

								{/* <img src="assets/demos/medical/images/doctors/1.jpg" alt="Dr. John Doe"/> */}
							<p>With more than 25 years of experience in Insurance Brokerage Company, especially Health Insurance, she handles local and multinational companies with variations in the number of participants with all the complexity of tailor-made product designs. With her excellent experience and network, Rays Solutions will be the leading and trusted broker.</p>
							</div>



						</div>



						{/* <div className="team">

							<div className="team-image">

								<img src="assets/demos/medical/images/doctors/6.jpg" alt="Dr. John Doe"/>

							</div>

							<div className="team-desc">

								<div className="team-title"><h4>Dr. Hugh Baldwin</h4><span>Cardiologist</span></div>

							</div>

						</div>



						<div className="team">

							<div className="team-image">

								<img src="assets/demos/medical/images/doctors/7.jpg" alt="Dr. John Doe"/>

							</div>

							<div className="team-desc">

								<div className="team-title"><h4>Dr. Erika Todd</h4><span>Neurologist</span></div>

							</div>

						</div>



						<div className="team">

							<div className="team-image">

								<img src="assets/demos/medical/images/doctors/8.jpg" alt="Dr. John Doe"/>

							</div>

							<div className="team-desc">

								<div className="team-title"><h4>Dr. Randy Adams</h4><span>Dentist</span></div>

							</div>

						</div>



						<div className="team">

							<div className="team-image">

								<img src="assets/demos/medical/images/doctors/9.jpg" alt="Dr. John Doe"/>

							</div>

							<div className="team-desc">

								<div className="team-title"><h4>Dr. Alan Freeman</h4><span>Eye Specialist</span></div>

							</div>

						</div> */}



					</div>



				</div>

			</div>

		</section>

        </>
    )
}

export default About
