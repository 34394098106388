/* eslint-disable jsx-a11y/anchor-is-valid */
// import { render } from '@testing-library/react';
import React, { Component } from 'react';
import {Link, NavLink} from 'react-router-dom';
// import './Navbar.css';


class Navbar extends Component {

render(){

	const activeNav = {
        color: "#FFF",
        textShadow: "1px 1px 1px rgba(0,0,0,0.2)",
        backgroundColor: "#284587"
      };

    return (
        <>


<header id="header" data-menu-padding="28" data-sticky-menu-padding="8">

<div id="header-wrap">

    <div className="container">

        <div className="header-row">





            <div id="logo">

                <a href="/" className="standard-logo"><img src="assets/images/logo.png" alt="Rays-Solution"/></a>

                <a href="/" className="retina-logo"><img src="assets/images/logo.png" alt="Rays-Solution"/></a>

            </div>



            <div id="primary-menu-trigger">

                <svg className="svg-trigger" viewBox="0 0 100 100"><path d="m 30,33 h 40 c 3.722839,0 7.5,3.126468 7.5,8.578427 0,5.451959 -2.727029,8.421573 -7.5,8.421573 h -20"></path><path d="m 30,50 h 40"></path><path d="m 70,67 h -40 c 0,0 -7.5,-0.802118 -7.5,-8.365747 0,-7.563629 7.5,-8.634253 7.5,-8.634253 h 20"></path></svg>

            </div>





            <nav className="primary-menu style-3 menu-spacing-margin">



                <ul className="menu-container top-links ">

                    <li className='top-links-item'><NavLink to='/' exact activeStyle={activeNav} className="menu-link" ><div>Home</div></NavLink></li>

                    <li className='top-links-item'><NavLink to='/About' activeStyle={activeNav} className="menu-link" ><div>About Us</div></NavLink></li>

                    <li className='top-links-item'><NavLink to='/Service' activeStyle={activeNav} className="menu-link" ><div>Our Services</div></NavLink></li>

                    <li className='top-links-item'><NavLink to='/Contact' activeStyle={activeNav} className="menu-link" ><div>Contact Us</div></NavLink></li>

                    <li className="top-links-item"><a href="#"><img src="assets/images/icons/flags/eng.png" style={{width:"15px", marginRight:"4px"}} alt="English"/>English</a>

                        <ul className="top-links-sub-menu">

                            <li className="top-links-item"><Link to='/' ><img src="assets/images/icons/flags/indonesia.png" alt="Indonesia"/> Indonesia</Link></li>

                            <li className="top-links-item"><Link to='/' ><img src="assets/images/icons/flags/eng.png" alt="English"/> English</Link></li>


                        </ul>

                    </li>
                    {/* <li className="menu-item"><a className="menu-link" href="demo-medical-doctors.html"><div>Doctors</div></a>

                        <ul className="sub-menu-container">

                            <li className="menu-item"><a className="menu-link" href="demo-medical-doctors-lists.html"><div>2 columns - List style</div></a></li>

                            <li className="menu-item"><a className="menu-link" href="demo-medical-doctors-3.html"><div>3 columns</div></a></li>

                            <li className="menu-item"><a className="menu-link" href="demo-medical-doctors.html"><div>4 columns</div></a></li>

                        </ul>

                    </li> */}

                    {/* <li className="menu-item"><a className="menu-link" href="demo-medical-blog.html"><div>Blog</div></a></li> */}

                    {/* <li className="menu-item"><a className="menu-link" href="demo-medical-contact.html"><div>Contact</div></a></li> */}

                </ul>



            </nav>



        </div>

    </div>

</div>

<div className="header-wrap-clone"></div>

</header>
        </>
    )
}
}

export default Navbar
