/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../../App.css";
// import {Link} from 'react-router-dom';

function Misc() {
    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Miscellaneous Insurance</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Service</li>

    </ol> */}

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row gutter-40 col-mb-80">
						{/* <!-- Post Content
						============================================= --> */}
						<div className="postcontent col-lg-12">

							<div className="single-post mb-0">

								{/* <!-- Single Post
								============================================= --> */}
								<div className="entry clearfix">

									{/* <!-- Entry Title
									============================================= --> */}
									{/* <div className="entry-title">
										<h2>This is a Standard post with a Preview Image</h2>
									</div> */}
                                    {/* <!-- .entry-title end --> */}

									{/* <!-- Entry Meta
									============================================= --> */}
									{/* <div className="entry-meta">
										<ul>
											<li><i className="icon-calendar3"></i> 10th July 2021</li>
											<li><a href="#"><i className="icon-user"></i> admin</a></li>
											<li><i className="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="#"><i className="icon-comments"></i> 43 Comments</a></li>
											<li><a href="#"><i className="icon-camera-retro"></i></a></li>
										</ul>
									</div> */}
                                    {/* <!-- .entry-meta end --> */}

									{/* <!-- Entry Image
									============================================= --> */}
									<div className="entry-image">
										<a href="#"><img src="/assets/images/custom/pages/banner/14_ASURANSI_ANEKA.jpg" alt="Blog Single"/></a>
									</div>
                                    {/* <!-- .entry-image end --> */}

									{/* <!-- Entry Content
									============================================= --> */}
									<div className="entry-content mt-0">

										<h3>1. MONEY INSURANCE</h3>
										<p>Insurance that provides protection against loss and loss of money for any reason, whether the money is kept in a cash in safe, cash in a cashier box, or when money is about to be taken or deposited in a bank (cash in transit).</p>

										<h4>A. CASH IN SAVE INSURANCE</h4>
										<p>Providing a loss guarantee to the insured for storing money in a safe that arises as a result of loss, theft, robbery, demolition, embezzlement and other similar actions carried out by non-insured or persons authorized to manage the money.</p>
										<p>Guarantee period: 24 hours and the insurance policy is issued for a certain period of time, generally 1 year.</p>

										{/* <blockquote><p>Vestibulum id ligula porta felis euismod semper. Sed posuere consectetur est at lobortis. Aenean eu leo quam. Pellentesque ornare sem lacinia quam venenatis vestibulum. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit. Donec ullamcorper nulla non metus auctor fringilla. Vestibulum id ligula porta felis euismod semper.</p></blockquote> */}
										<h4>B. CASH IN CASHIER BOX INSURANCE</h4>
										<p>Provide guarantees for loss to the insured against the deposit of money in the cash register (petty cash) arising from loss, theft, robbery, demolition, mugging, and other similar actions carried out by non-insured or persons authorized to manage the money.</p>

										<h4>C. CASH IN TRANSIT</h4>
										<p>Provide guarantees for loss to the insured for remittances arising from loss, theft, robbery, demolition, embezzlement or other similar actions committed by non-insured or persons authorized to manage the money.</p>
										<p>Guarantee period: until the money sent is received by the recipient or arrives at its destination.</p>
										<h4>MACHINERY BREAKDOWN INSURANCE</h4>
										<p>Provide guarantees for damage or loss to machines that are damaged or stop operating caused by damage to the machine itself (internal) and not from danger that comes from outside (external). Example: insurance for factory machines, refrigerators, generators, etc</p>


										<h3>2. FIDELITY GUARANTEE (FG)</h3>
										<p>The guarantee given to the entrepreneur/company owner for the possibility of any losses suffered as a result of dishonesty/fraud acts committed by the said employee.</p>
										{/* <!-- Post Single - Content End --> */}

										{/* <!-- Tag Cloud
										============================================= --> */}
										<div className="tagcloud clearfix bottommargin">
											<a href="#">insurance</a>
											<a href="#">misc</a>
											<a href="#">general</a>
											<a href="#">money</a>
											<a href="#">cash</a>
											<a href="#">guarantee</a>
										</div>
                                        {/* <!-- .tagcloud end --> */}

										<div className="clear"></div>

										{/* <!-- Post Single - Share
										============================================= --> */}
										{/* <div className="si-share border-0 d-flex justify-content-between align-items-center">
											<span>Share this Post:</span>
											<div>
												<a href="#" className="social-icon si-borderless si-facebook">
													<i className="icon-facebook"></i>
													<i className="icon-facebook"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-twitter">
													<i className="icon-twitter"></i>
													<i className="icon-twitter"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-pinterest">
													<i className="icon-pinterest"></i>
													<i className="icon-pinterest"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-gplus">
													<i className="icon-gplus"></i>
													<i className="icon-gplus"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-rss">
													<i className="icon-rss"></i>
													<i className="icon-rss"></i>
												</a>
												<a href="#" className="social-icon si-borderless si-email3">
													<i className="icon-email3"></i>
													<i className="icon-email3"></i>
												</a>
											</div>
										</div> */}
                                        {/* <!-- Post Single - Share End --> */}

									</div>
								</div>
                                {/* <!-- .entry end --> */}

								{/* <!-- Post Navigation
								============================================= --> */}
								<div className="row justify-content-between col-mb-30 post-navigation">
									<div className="col-12 col-md-auto text-center">
										<a href="service">&lArr; Back to our services</a>
									</div>

									{/* <div className="col-12 col-md-auto text-center">
										<a href="#">This is an Embedded Audio Post &rArr;</a>
									</div> */}
								</div>


								{/* <!-- Comments
								============================================= --> */}
								<div id="comments" className="clearfix">



									<div className="clear"></div>

									{/* <!-- Comment Form
									============================================= --> */}

                                    {/* <!-- #respond end --> */}

								</div>
                                {/* <!-- #comments end --> */}

							</div>

						</div>
                        {/* <!-- .postcontent end --> */}

					</div>

				</div>
			</div>
		</section>



        </>
    )
}

export default Misc
