/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import "../../App.css";
import Banner from '../Banner';
// import {
// 	Link,
// 	NavLink
//   } from "react-router-dom";



function Home() {


		// const backgroundImg = {
		// 	background: `url('assets/demos/medical/images/section-bg.jpg') center center no-repeat `,
		// 	backgroundSize : "cover",
		// 	minHeight: "250px"
		//   };



        return (
<>

<Banner/>
<section id="content">

<div className="content-wrap">
{/* <h2 className="center">Why Choose Ray Solutions..</h2> */}
<div className="container clearfix">
					{/* carousel start */}
		{/* <div id="oc-products" className="owl-carousel products-carousel carousel-widget" data-pagi="false" data-items-xs="1" data-items-sm="2" data-items-md="3" data-items-lg="4">

				<div className="oc-item">

					<div className="box-custom">

								<div className="fbox-content">

									<h3 className="center">Wider Choice &amp; Value</h3>

									<a href="#">

										<div className="line line-sm"></div>
										<img src="assets/images/custom/wider.png" className="rounded mx-auto d-block" alt="Clients"/>
										</a>
										<div className="line line-sm"></div>
									<p>As a broker, we don’t represent just one single insurance company. We are able to offer coverage from a broad range of reputable insurers, making sure that the choice is in your best interest best fitting your coverage needs and at the best possible rates</p>

								</div>

							</div>


				</div>

				<div className="oc-item">
				<div className="box-custom">

					<div className="fbox-content">

					<h3 className="center">Personalized Service</h3>

					<a href="#">

					<div className="line line-sm"></div>
					<img src="assets/images/custom/personal.png" className="rounded mx-auto d-block" alt="Clients"/>
					</a>
				<div className="line line-sm"></div>
					<p>We understand that every business is different. Our team delivers total risk solutions that go way beyond insurance products. We take an innovative approach to risk management, conducting ongoing research to inform our solutions and ensure they’re relevant to your industry and business</p>

				</div>

				</div>

				</div>

				<div className="oc-item">
				<div className="box-custom">

					<div className="fbox-content">

					<h3 className="center">Client Focused</h3>

					<a href="#">

					<div className="line line-sm"></div>
					<img src="assets/images/custom/focus.png" className="rounded mx-auto d-block" alt="Clients"/>
					</a>
				<div className="line line-sm"></div>
					<p>Attention to detail and quality of work, along with years of professional experience, make us the best choice to partner with</p>

				</div>

				</div>

				</div>

				<div className="oc-item">
				<div className="box-custom">

					<div className="fbox-content">

					<h3 className="center">Reliable Network</h3>

					<a href="#">

					<div className="line line-sm"></div>
					<img src="assets/images/custom/wider.png" className="rounded mx-auto d-block" alt="Clients"/>
					</a>
				<div className="line line-sm"></div>
					<p>We value the relationships we have built with our Clients and Insurers. The base for these strong bonds is our commitment to professionalism and looking at it as one overall business partnership</p>

				</div>

				</div>

				</div>

				<div className="oc-item">
				<div className="box-custom">

					<div className="fbox-content">

					<h3 className="center">Claim Advocacy</h3>

					<a href="#">
					<div className="line line-sm"></div>
					<img src="assets/images/custom/wider.png" className="rounded mx-auto d-block" alt="Clients"/>
					</a>
				<div className="line line-sm"></div>
					<p>We have an excellent dedicated claims team with a strong track record of ensuring successful claims settlements honoring the insured's rights</p>

				</div>

				</div>

				</div>


		    </div> */}


					{/* carousel end */}


					<h3 className="center">Our Services</h3>
    		<div id="oc-clients" className="owl-carousel image-carousel carousel-widget clients-grid" data-margin="10" data-nav="false" data-pagi="true" data-items-xs="2" data-items-sm="3" data-items-md="4" data-items-lg="5" data-items-xl="6" style={{marginBottom:"50px"}}>


						<div className="oc-item">
							<li className="grid-item"><a href="invidual"><img src="assets/images/custom/pages/InvidualHealth.jpg" alt="Clients"/></a></li>
							<li className="grid-item"><a href="motor-vehicle"><img src="assets/images/custom/pages/07_MOTOR_VEHICLE_INSURANCE.jpg" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="group-health"><img src="assets/images/custom/pages/02_GROUP_HEALTH_INSURANCE.jpg" alt="Clients"/></a></li>
							<li className="grid-item"><a href="property"><img src="assets/images/custom/pages/08_PROPERTY_INSURANCE.jpg" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="personal-accident"><img src="assets/images/custom/pages/03_GROUP_PERSONAL_ACCIDENT.jpg" alt="Clients"/></a></li>
							<li className="grid-item"><a href="heavy-equipment"><img src="assets/images/custom/pages/09_HEAVY_EQUIPMENT_INSURANCE.jpg" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="group-life"><img src="assets/images/custom/pages/04_GROUP_TERM_LIFE.jpg" alt="Clients"/></a></li>
							<li className="grid-item"><a href="engineering"><img src="assets/images/custom/pages/10_ENGINEERING_INSURANCE.jpg" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="travel"><img src="assets/images/custom/pages/05_TRAVEL_INSURANCE.jpg" alt="Clients"/></a></li>
							<li className="grid-item"><a href="liability"><img src="assets/images/custom/pages/11_LIABILITY_INSURANCE.jpg" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="tpa"><img src="assets/images/custom/pages/06_THIRD_PARTY_ADMINISTRATOR.jpg" alt="Clients"/></a></li>
							<li className="grid-item"><a href="marine"><img src="assets/images/custom/pages/12_MARINE_CARGO_INSURANCE.jpg" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="surety"><img src="assets/images/custom/pages/13_SURETY_BOND.jpg" alt="Clients"/></a></li>
							<li className="grid-item"><a href="misc"><img src="assets/images/custom/pages/14_MISCELLANEOUS_INSURANCE.jpg" alt="Clients"/></a></li>
						</div>



					</div>


					<h3 className="center">Our Network</h3>
    		<div id="oc-clients" className="owl-carousel image-carousel carousel-widget clients-grid" data-margin="10" data-nav="false" data-pagi="true" data-items-xs="2" data-items-sm="3" data-items-md="4" data-items-lg="5" data-items-xl="6">


						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/1.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/2.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/3.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/4.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/5.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/6.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/7.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/8.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/9.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/10.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/11.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/12.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/13.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/14.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/15.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/16.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/17.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/18.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/19.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/20.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/21.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/22.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/23.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/24.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/25.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/26.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/27.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/28.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/29.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/30.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/31.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/32.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/33.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/34.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/35.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/36.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/37.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/38.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/39.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/40.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/41.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/42.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/43.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/44.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/45.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/46.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/47.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/48.png" alt="Clients"/></a></li>
						</div>
						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/49.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/50.png" alt="Clients"/></a></li>
						</div>

						<div className="oc-item">
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/51.png" alt="Clients"/></a></li>
							<li className="grid-item"><a href="#"><img src="assets/images/custom/brand/52.png" alt="Clients"/></a></li>
						</div>



					</div>

	</div>

	</div>
</section>


</>



        );
    }


export default Home;