import React, { useState } from "react";

const ContactForm = () => {
  const [status, setStatus] = useState("Submit");
  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus("Sending...");
    const { name, email, phone, type, address, message } = e.target.elements;
    let details = {
      name: name.value,
      email: email.value,
      message: message.value,
      phone: phone.value,
      type: type.value,
      address: address.value
    };

    let response = await fetch("https://rays-solutions.co.id/sendmail/test.php", {
      method: "POST",
      headers: {
        "Content-Type": "application/json;charset=utf-8"
      },
      body: JSON.stringify(details)
    });


    setStatus("Submit");
    let result = await response.json();
     alert(result.status);
    document.getElementById("contactform").reset();
  };

  return (


<form className="mb-0 contactform width-90" id="contactform" name="template-contactform" onSubmit={handleSubmit} >

{/* <div className="form-process">
    <div className="css3-spinner">
        <div className="css3-spinner-scaler"></div>
    </div>
</div> */}

<div className="row">
    <div className="col-12 form-group">
        <label htmlFor="name">Name <small>*</small></label>
        <input  type="text" id="name" name="name" className="sm-form-control required" />
    </div>

    <div className="col-12 form-group">
        <label htmlFor="email">Email <small>*</small></label>
        <input  type="email" id="email" name="email" className="required email sm-form-control" />
    </div>

    <div className="col-12 form-group">
        <label htmlFor="phone">Handphone/WA <small>*</small></label>
        <input type="text" id="phone" name="phone" className="sm-form-control required" />
    </div>

    {/* <div className="w-100"></div> */}

    <div className="col-12 form-group">
        <label htmlFor="address">Address <small>*</small></label>
        <input type="text" id="address" name="address" className="required sm-form-control" />
    </div>

    <div className="col-md-12 form-group">
        <label htmlFor="type">Insurance Type</label>
        <select id="type" name="type" className="sm-form-control">
            <option value="">-- Select One --</option>
            <option value="Employee Benefit">Employee Benefit</option>
            <option value="General Insurance">General Insurance</option>
            <option value="Individual and Health Insurance">Individual &amp; Family Health Insurance</option>
            <option value="Group Health Insurance">Group Health Insurance</option>
            <option value="Group Personal Accident">Group Personal Accident</option>
            <option value="Group Term Life">Group Term Life</option>
            <option value="Travel Insurance">Travel Insurance</option>
            <option value="Third Party Administrator">Third Party Administrator (TPA)</option>
            <option value="Motor Vehicle Insurance">Motor Vehicle Insurance</option>
            <option value="Property Insurance">Property Insurance</option>
            <option value="Heavy Equipment Insurance">Heavy Equipment Insurance</option>
            <option value="Engineering Insurance">Engineering Insurance</option>
            <option value="Liability Insurance">Liability Insurance</option>
            <option value="Marine and Cargo Insurance">Marine &amp; Cargo Insurance</option>
            <option value="Surety Bond">Surety Bond</option>
            <option value="Miscellaneous Insurance">Miscellaneous Insurance</option>


        </select>
    </div>

    <div className="w-100"></div>

    <div className="col-12 form-group">
        <label htmlFor="message">Message <small>*</small></label>
        <textarea   className="required sm-form-control" id="message" name="message" rows="6" cols="30"></textarea>
    </div>

    <div className="col-12 form-group d-none">
        <input type="text" id="template-contactform-botcheck" name="template-contactform-botcheck" className="sm-form-control" />
    </div>

    {/* <div className="col-12 row form-group ">
    <div class="col-4">
        <label htmlFor="capcha">Capcha <small>*</small></label>
        </div>
        <div class="col-8">
        <input type="text" id="template-contactform-subject" name="subject" value="" className="required sm-form-control" />
        </div>
    </div> */}

    <div className="col-12 form-group">
        <button name="submit" type="submit" id="submit" tabIndex="5" value="Submit" className="button button-3d m-0">{status}</button>
    </div>
</div>

{/* <input type="hidden" name="prefix" value="template-contactform-"/> */}

</form>
  );
};

export default ContactForm;