/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import "../../../App.css";
// import {Link} from 'react-router-dom';

function Marine() {
    return (
        <>

<section id="page-title" className="bg-image page-title-dark">



<div className="container clearfix">

    <h1>Marine &amp; Cargo Insurance</h1>

    {/* <span>Our service for our repected clients</span> */}

    {/* <ol className="breadcrumb">

        <li className="breadcrumb-item"><a href="/">Home</a></li>

        <li className="breadcrumb-item active" aria-current="page">Service</li>

    </ol> */}

</div>
</section>

<section id="content">
			<div className="content-wrap">
				<div className="container clearfix">

					<div className="row gutter-40 col-mb-80">
						{/* <!-- Post Content
						============================================= --> */}
						<div className="postcontent col-lg-12">

							<div className="single-post mb-0">

								{/* <!-- Single Post
								============================================= --> */}
								<div className="entry clearfix">

									{/* <!-- Entry Title
									============================================= --> */}
									{/* <div className="entry-title">
										<h2>This is a Standard post with a Preview Image</h2>
									</div> */}
                                    {/* <!-- .entry-title end --> */}

									{/* <!-- Entry Meta
									============================================= --> */}
									{/* <div className="entry-meta">
										<ul>
											<li><i className="icon-calendar3"></i> 10th July 2021</li>
											<li><a href="#"><i className="icon-user"></i> admin</a></li>
											<li><i className="icon-folder-open"></i> <a href="#">General</a>, <a href="#">Media</a></li>
											<li><a href="#"><i className="icon-comments"></i> 43 Comments</a></li>
											<li><a href="#"><i className="icon-camera-retro"></i></a></li>
										</ul>
									</div> */}
                                    {/* <!-- .entry-meta end --> */}

									{/* <!-- Entry Image
									============================================= --> */}
									<div className="entry-image">
										<a href="#"><img src="/assets/images/custom/pages/banner/12_MARINE_INSURANCE.jpg" alt="Blog Single"/></a>
									</div>
                                    {/* <!-- .entry-image end --> */}

									{/* <!-- Entry Content
									============================================= --> */}
									<div className="entry-content mt-0">



										<h4>1. MARINE HULL</h4>
										<p>Provide coverage for the hull of the ship along with its engines and equipment, against damage or loss that arises as a result of shipping risks and sea hazards, as well as the consequences of risks guaranteed in the policy conditions.
										  The types of Hull Insurance Products that we offer:</p>
										  <ul style={{marginLeft:"2rem"}}>
											<li>A. MARINE HULL INSURANCE (MHI)</li>
												<span>Provide guarantees for damage or loss to ships, machinery and equipment from perils of the seas and navigational perils.</span>
											<li>B. BUILDER RISKS INSURANCE (MHI)</li>
												<span>Provide guarantees for the risks that occur during the construction of the ship (at the shipyard) until the delivery of the ship to the owner.</span>
											</ul>


											<h4>2. MARINE CARGO INSURANCE (MCI)</h4>
										<p>Provide protection to the Insured (the owner of the goods being transported) from damage/loss of the goods being transported (which are being transported) as a result of a disaster that may arise as a result of:</p>
										<ul style={{marginLeft:"2rem"}}>
											<li>Accident</li>
											<li>Drowning or sinking</li>
											<li>Fire</li>
											<li>Explosion</li>
											<li>Loose train tracks</li>
											<li>Theft, carelessness</li>
											<li>Accidents while loading/unloading</li>
											<li>Earthquakes, volcanic eruptions and lightning</li>
											</ul>
										{/* <!-- Post Single - Content End --> */}

										{/* <!-- Tag Cloud
										============================================= --> */}
										<div className="tagcloud clearfix bottommargin">
											<a href="#">insurance</a>
											<a href="#">marine</a>
											<a href="#">general</a>
											<a href="#">cargo</a>
											<a href="#">hull</a>
											<a href="#">protection</a>
										</div>
                                        {/* <!-- .tagcloud end --> */}

										<div className="clear"></div>

										{/* <!-- Post Single - Share
										============================================= --> */}

                                        {/* <!-- Post Single - Share End --> */}

									</div>
								</div>
                                {/* <!-- .entry end --> */}

								{/* <!-- Post Navigation
								============================================= --> */}
								<div className="row justify-content-between col-mb-30 post-navigation">
									<div className="col-12 col-md-auto text-center">
										<a href="service">&lArr; Back to our services</a>
									</div>

									{/* <div className="col-12 col-md-auto text-center">
										<a href="#">This is an Embedded Audio Post &rArr;</a>
									</div> */}
								</div>


								{/* <!-- Comments
								============================================= --> */}
								<div id="comments" className="clearfix">



									<div className="clear"></div>

									{/* <!-- Comment Form
									============================================= --> */}
								    {/* <!-- #respond end --> */}

								</div>
                                {/* <!-- #comments end --> */}

							</div>

						</div>
                        {/* <!-- .postcontent end --> */}

						{/* <!-- Sidebar
						============================================= --> */}

                        {/* <!-- .sidebar end --> */}
					</div>

				</div>
			</div>
		</section>



        </>
    )
}

export default Marine
